<template>
    <div class="ReportTestinfo full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <v-dialog ref="pathdialog" v-model="pathmodal" :return-value.sync="exportPath" persistent width="500px">
                <div>
                    <div class="dialogcontainer pa-8">
                        <p style="font-weight: 500">{{ $t('report.selectPathExport') }}</p>
                        <v-list>
                            <v-list-item-group
                                v-model="exportPath"
                                color="disiIrisblue"
                                class="list mt-4 mb-6"
                                mandatory
                                id="pathmodal-list"
                            >
                                <v-list-item
                                    v-for="(item) in this.pathlist"
                                    :key="item.id"
                                    :value="item.id"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.name"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>

                        <div class="d-flex align-center mt-0 mb-8">
                            <switch-on-off name="graphOnReport-export" :class="{'active': exportGraph}" class="mr-4"/>
                            <p class="ml-3">{{ $t('report.exportGraph') }}</p>
                        </div>

                        <div class="button-container d-flex justify-space-between">
                            <v-btn elevation="0" class="content-btn small" @click="pathmodal = false;">
                                {{ $t('footerButton.cancel') }}
                            </v-btn>
                            <v-btn elevation="0" class="content-btn small" :disabled="exportPath == ''"
                                   @click="$refs.pathdialog.save(exportPath); doExport()">{{ $t('footerButton.save') }}
                            </v-btn>
                        </div>
                    </div>
                </div>
            </v-dialog>
            <v-dialog ref="printdialog" v-model="printmodal" persistent width="500px">
                <div>
                    <div class="dialogcontainer pa-8">
                        <div class="d-flex align-center mt-0 mb-8">
                            <switch-on-off name="graphOnReport-print" :class="{'active': printGraph}" class="mr-4"/>
                            <p class="ml-3">{{ $t('report.printGraph') }}</p>
                        </div>
                        <div class="button-container d-flex justify-space-between">
                            <v-btn elevation="0" class="content-btn small" @click="printmodal = false;">
                                {{ $t('footerButton.cancel') }}
                            </v-btn>
                            <v-btn elevation="0" class="content-btn small" @click="printReport(); printmodal = false;">
                                {{ $t('footerButton.print') }}
                            </v-btn>
                        </div>
                    </div>
                </div>
            </v-dialog>
            <navigation-left-big/>
            <div class="content-area pa-5 flex-grow-1 bg-white">
                <v-row class="d-flex align-center">
                    <v-col class="col-4">
                        <p>{{ $t('general.status') }}</p>
                    </v-col>
                    <v-col class="col-8">
                        <text-input class="noAction" inputName="report-status" readonly/>
                    </v-col>
                </v-row>
                <v-row class="d-flex align-center">
                    <v-col class="col-4">
                        <p>{{ $t('general.test') }}</p>
                    </v-col>
                    <v-col class="col-8">
                        <text-input class="noAction" inputName="report-testSequence" readonly/>
                    </v-col>
                </v-row>
                <v-row class="d-flex align-center">
                    <v-col class="col-4">
                        <p>{{ $t('general.date') }}</p>
                    </v-col>
                    <v-col class="col-8">
                        <text-input class="noAction" inputName="report-dateTime" readonly/>
                    </v-col>
                </v-row>
                <v-row class="d-flex align-center">
                    <v-col class="col-4">
                        <p>{{ $t('report.productName') }}</p>
                    </v-col>
                    <v-col class="col-8">
                        <text-input class="noAction" inputName="report-testInfo-productName" readonly/>
                    </v-col>
                </v-row>
                <v-row class="d-flex align-center" v-if="report.testInfo.testType != 'QuickTest'">
                    <v-col class="col-4">
                        <p>{{ $t('report.productNumber') }}</p>
                    </v-col>
                    <v-col class="col-8">
                        <text-input class="noAction" inputName="report-testInfo-productNumber" readonly/>
                    </v-col>
                </v-row>
                <v-row class="d-flex align-center">
                    <v-col class="col-4">
                        <p>{{ $t('report.batchName') }}</p>
                    </v-col>
                    <v-col class="col-8">
                        <text-input class="noAction" inputName="report-testInfo-batch" readonly/>
                    </v-col>
                </v-row>
                <v-row class="d-flex align-center">
                    <v-col class="col-4">
                        <p>{{ $t('report.basketSerial') }}</p>
                    </v-col>
                    <v-col class="col-8">
                        <text-input class="noAction" inputName="report-testInfo-basketSerial" readonly/>
                    </v-col>
                </v-row>
                <v-row class="d-flex align-center">
                    <v-col class="col-4">
                        <p>{{ $t('report.stationSN') }}</p>
                    </v-col>
                    <v-col class="col-8">
                        <p class="readOnly">{{ report.testInfo.station }} / {{ report.testInfo.stationSerial }}</p>
                    </v-col>
                </v-row>
            </div>
        </div>

        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button buttonFunction="back" @footerButtonClick="goToReport"></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="delete"
                           :class="{'disabled': !checkUserRights('reportsDelete')}"
                           @footerButtonClick="deleteReport"></footer-button>
            <footer-button buttonFunction="export" :successCheck="showExportSuccess" :progress="showExportProgress"
                           @footerButtonClick="pathmodal = true"
                           :class="{'disabled': !checkUserRights('reportsExport')}"></footer-button>
            <footer-button buttonFunction="print" :successCheck="showPrintSuccess" :progress="showPrintProgress"
                           @footerButtonClick="printmodal = true"
                           :class="{'disabled': !checkUserRights('reportsPrint')}"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import NavigationLeftBig from '@/components/NavigationLeftBig.vue'
import FooterButton from '@/components/FooterButton.vue'
import TextInput from "@/components/TextInput.vue";
import {mapState} from "vuex";
import mixins from "@/mixins/mixins";
import SwitchOnOff from "@/components/SwitchOnOff.vue";

export default {
    name: 'ReportTestinfo',
    components: {
        SwitchOnOff,
        NavigationLeftBig,
        TextInput,
        FooterButton,
    },
    props: {},
    data() {
        return {
            showPrintProgress: false,
            showPrintSuccess: false,
            showExportProgress: false,
            showExportSuccess: false,
            pathmodal: false,
            exportPath: '',
            printmodal: false,
        }
    },
    computed: {
        ...mapState([
            'report',
            'pathlist',
            'graphOnReport'
        ]),
        exportGraph() {
            return this.$store.state.graphOnReport.export;
        },
        printGraph() {
            return this.$store.state.graphOnReport.print;
        },
    },
    methods: {
        goToReport() {
            this.$router.push('report');
        },
        doExport() {
            this.showExportProgress = true;
            this.$store.dispatch('postAxiosNoSetter', ['/disi/reports/export',
                {
                    id: this.report.id,
                    pathId: this.exportPath,
                    includeGraph: this.graphOnReport.export
                }])
                .then(response => {
                    this.showExportProgress = false;
                    if (response.status === 200) {
                        this.showExportSuccess = true;
                        setTimeout(() => {
                            this.showExportSuccess = false;
                        }, 800)
                    }
                });
        },
        printReport() {
            this.showPrintProgress = true;
            this.$store.dispatch('postAxiosNoSetter', ['/disi/reports/print',
                {
                    id: this.report.id,
                    includeGraph: this.graphOnReport.print
                }])
                .then(response => {
                    this.showPrintProgress = false;
                    if (response.status === 200) {
                        this.showPrintSuccess = true;
                        setTimeout(() => {
                            this.showPrintSuccess = false;
                        }, 800)
                    }
                });
        },
        deleteReport() {
            this.$store.dispatch('postAxiosNoSetter', ['/disi/reports/delete', {'id': [this.report.id]}])
                .then(response => {
                    if (response.status === 200) {
                        this.goToReport();
                    }
                })
        },
    },
    created() {
        this.$store.dispatch('getAxiosSetter', ['/admin/share/pathlist', 'pathlist']);
        this.$store.dispatch('getAxiosSetter', ['/admin/printer/settings', 'printer'])
            .then(response => {
                if (response.status === 200) {
                    this.$store.commit('setGraphOnReport', ['print'], this.$store.state.printer.printGraph)
                }
            })
        this.$store.dispatch('getAxiosSetter', ['/admin/pdf/settings', 'pdfExport'])
            .then(response => {
                if (response.status === 200) {
                    this.$store.commit('setGraphOnReport', ['export'], this.$store.state.pdfExport.exportGraph)
                }
            })
    },
    mixins: [mixins],

}
</script>
<style scoped lang="scss">
</style>